const isInThePast = (date: string) => {
  const dateForCompare = (new Date(date).getTime());
  const nowDate = Date.now();

  return nowDate > dateForCompare;
};

export {
  isInThePast,
};
