import { computed, reactive } from '@nuxtjs/composition-api';

import { ProductPageInterface, UseProductPageStateInterface } from '~/composables/useProductPageState/useProductPageState';

const state = reactive<ProductPageInterface>({
  activeTab: null,
  brand: null,
  compatibilityBrand: null,
});

/**
 * Global store for managing product page state.
 *
 * See the {@link UseProductPageStateInterface} for a list of methods and values available in this composable.
 */
export function useProductPageState(): UseProductPageStateInterface {
  const setActiveTab = (newState) => {
    state.activeTab = newState;
  };

  const setBrand = (newState) => {
    state.brand = newState;
  };

  const setCompatibilityBrand = (newState) => {
    state.compatibilityBrand = newState;
  };

  return {
    activeTab: computed(() => state.activeTab),
    brand: computed(() => state.brand),
    compatibilityBrand: computed(() => state.compatibilityBrand),
    setActiveTab,
    setBrand,
    setCompatibilityBrand,
  };
}

export default useProductPageState;
export * from './useProductPageState';
