




















import { defineComponent } from '@nuxtjs/composition-api';

import { SearchInput } from '~/components/common/SearchInput';
import Logo from '~/components/Logo/Logo.vue';

import CartIcon from '../MicrocartIcon/CartIcon.vue';
import MyAccount from '../MyAccount/MyAccount.vue';

export default defineComponent({
  name: 'TopNavigation',

  components: {
    CartIcon,
    SearchInput,
    Logo,
    MyAccount,
  },
});
