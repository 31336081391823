import {
  computed,
} from '@nuxtjs/composition-api';

import { useAttributesStore } from '~/stores/attributes';

import type {
  UseAttributesInterface,
} from './useAttributes';

export function useAttributes(): UseAttributesInterface {
  const attributesStore = useAttributesStore();
  const load = () => attributesStore.load();

  const attributes = computed(() => attributesStore.attributes);

  const getOptionLabel = (attributeKey: string, optionIdNumber: string | number): string => {
    const optionId = `${optionIdNumber}`;

    if (!(attributeKey && optionId) || !attributes.value?.length) {
      return optionId;
    }

    const optionIds = optionId.split(',');

    if (optionIds.length > 1) {
      const { label, isCached } = attributesStore.getLabelById(attributeKey, optionId);

      if (isCached) {
        return label;
      }

      const complexLabel = optionIds.reduce((acc, curr, currentIndex) => {
        const separator = optionIds.length - 1 > currentIndex ? ',' : '';

        return `${acc} ${attributesStore.getLabelById(attributeKey, curr)?.label}${separator}`;
      }, '');

      attributesStore.updateLabels(attributeKey, optionId, complexLabel);

      return complexLabel;
    }

    const { label, isCached } = attributesStore.getLabelById(attributeKey, optionId);

    if (!isCached) {
      attributesStore.updateLabels(attributeKey, optionId, label);
    }

    return label;
  };

  return {
    load,
    getOptionLabel,
    attributes,
  };
}

export * from './config';
export * from './useAttributes';

export default useAttributes;
