import {
  computed, useContext,
} from '@nuxtjs/composition-api';
import type { TranslateResult } from 'vue-i18n/types';

import { isHP, isPrinter } from '~/helpers/productHelpers';
import { CompatibilityListInterface } from '~/modules/GraphQL/types';

import { PRODUCT_TABS } from './constants';
import { TabsConfigParams } from './useProductTabs';

export function useProductTabs({ product, compatibleTitle, brandTitle }: TabsConfigParams) {
  const { app } = useContext();

  const showDynamicHpTab = computed<boolean>(() => isPrinter(product.value) && isHP(product.value) && product.value.hp_dynamic_security);

  const compatibilityList = computed<CompatibilityListInterface[]>(() => {
    const list = product.value.compatibility_list || [];

    return [...list].sort((a, b) => a.name.localeCompare(b.name));
  });

  const compatibilityTitle = computed<TranslateResult>(() => {
    const compatibilityBrands = [...new Set(compatibilityList.value?.map((item) => item.url?.split('/')?.[2]))];
    const isMultiBrand = compatibilityBrands?.length > 1;
    const brandTextPart = brandTitle.value && !isMultiBrand ? `${brandTitle.value} printers that use ` : 'Printers that use ';

    return `${brandTextPart} ${compatibleTitle.value} cartridges`;
  });

  const tabsConfig = computed(() => {
    const config = [
      {
        id: PRODUCT_TABS.reviews,
        component: 'Reviews',
        title: app.i18n.t('Reviews'),
        hidden: product.value.review_count === 0,
        props: {
          product: product.value,
        },
      },
      {
        id: PRODUCT_TABS.description,
        component: 'Description',
        title: app.i18n.t('About this product'),
        hidden: !product.value.description?.html,
        props: {
          product: product.value,
        },
      },
      {
        id: PRODUCT_TABS.specification,
        component: 'Specification',
        title: app.i18n.t('Specifications'),
        props: {
          product: product.value,
        },
      },
      {
        id: PRODUCT_TABS.compatibility,
        component: 'CompatibilityList',
        title: compatibilityTitle.value,
        props: {
          'compatibility-list': compatibilityList.value,
        },
        hidden: compatibilityList.value.length === 0,
      },
      {
        id: PRODUCT_TABS.reasons,
        component: 'ReasonsToBuy',
        title: app.i18n.t('Reasons to buy'),
        hidden: !product.value.reasons_to_buy,
        props: {
          product: product.value,
        },
      },
      {
        id: PRODUCT_TABS.emptyReviews,
        component: 'EmptyReviews',
        title: app.i18n.t('Reviews'),
        hidden: product.value.review_count > 0,
        props: {
          product: product.value,
        },
      },
      {
        id: PRODUCT_TABS.security,
        component: 'Security',
        title: app.i18n.t('Dynamic security enabled printer'),
        hidden: !showDynamicHpTab.value,
      },
    ];

    return config.filter((tab) => !tab.hidden);
  });

  return {
    tabsConfig,
  };
}

export * from './constants';
export * from './useProductTabs';
export default useProductTabs;
