import { useContext, useRouter } from '@nuxtjs/composition-api';

import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import type { Product } from '~/modules/catalog/product/types';
import useCart from '~/modules/checkout/composables/useCart';
import { CartItemInterface, ProductInterface } from '~/modules/GraphQL/types';

export const useAddToCart = () => {
  const {
    addItem: addItemToCartBase,
    isInCart,
    loading,
    cart,
  } = useCart();
  const router = useRouter();
  const { app } = useContext();
  const { getProductPath } = useProduct();
  const addItemToCart = async (params: { product: Product, quantity: number }) => {
    const { product, quantity } = params;
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    const productType = product.__typename;

    switch (productType) {
      case 'SimpleProduct':
      case 'AlternativeProduct':
        await addItemToCartBase({
          product,
          quantity,
        });
        break;
      case 'BundleProduct':
      case 'ConfigurableProduct':
      case 'GroupedProduct':
        const path = app.localeRoute(getProductPath(product));

        await router.push(path);
        break;
      default:
        throw new Error(`Product Type ${productType} not supported in add to cart yet`);
    }
  };

  const formatProductMessages = (messages: Record<string, any>): string => Object.values(messages).join(', ');

  // eslint-disable-next-line max-len
  const getQtyInCart = (product: ProductInterface): number => cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid || cartItem?.product?.sku === product.sku)?.quantity;
  // eslint-disable-next-line max-len
  const getItemFromCart = (product: ProductInterface): CartItemInterface => cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid);

  return {
    addItemToCart,
    isInCart,
    formatProductMessages,
    getQtyInCart,
    getItemFromCart,
    loading,
  };
};
