import { Aggregation } from '~/modules/GraphQL/types';

/**
 * Sorts array of filters by provided config
 * @param {Array<Aggregation>} filters - filters aggregation
 * @param {Array<String>} config - array with filters order
 * @returns Sorted array of filters
 */
export const sortFiltersByConfig = (filters, config): Aggregation[] => filters.sort((a, b) => {
  const aIndex = config.indexOf(a.attribute_code);
  const bIndex = config.indexOf(b.attribute_code);

  if (aIndex === -1 || (aIndex > bIndex && bIndex !== -1)) {
    return 1;
  }

  if ((aIndex < bIndex) || (aIndex >= 0 && bIndex === -1)) {
    return -1;
  }

  return 0;
});
