import {
  fragmentAlternativeProduct,
  fragmentCashback, fragmentPrice, fragmentProduct, fragmentStock,
} from '~/customQueries/productDetails';
/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination on printers page.
 */
export default `
  query getSearchData($search: String, $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      aggregations {
        label
        count
        attribute_code
        options {
          count
          label
          value
          __typename
        }
        position
        __typename
      }
      items {
        buy_together {
            sku
        }
        capacity
        cartridge_condition
        due_in_date
        group
        price_tiers {
          quantity
          final_price {
            value
          }
          final_price_incl_tax {
            value
          }
        }
        printer_condition
        printer_feature_bullet
        printer_main_functions_bullet
        print_speed_black_normal
        print_speed_colour_normal
        return_program_hover_over
        return_program_hover_show
        sku_pack_component
        trade_in_lightbox_html
        trade_in_value
        warranty_ext_lightbox_html
        warranty_ext_years
        yields {
          color
          swatches
          yield_text
          yield
        }
        alternative_product {
          arrow_rule
          price_difference_incl_tax
          price_difference
          yields_difference
          stock_status
          only_x_left_in_stock
          ...AlternativeProduct
          ...Price
          ...Stock
        }
        __typename
        ...BaseProduct
        ...Cashback
        ...Price
        ...Stock
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
  ${fragmentAlternativeProduct}
  ${fragmentProduct}
  ${fragmentCashback}
  ${fragmentPrice}
  ${fragmentStock}
`;
