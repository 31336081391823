import { Plugin } from '@nuxt/types';
import { ref, set } from '@nuxtjs/composition-api';
import { PiniaPluginContext } from 'pinia';

const storeConfigPlugin: Plugin = ({ $pinia }) => {
  const data = {
    storeConfig: {
      store_code: process.env.STORE_CODE || 'default',
      default_title: process.env.DEFAULT_TITLE || 'Cartridge Save',
      store_name: process.env.STORE_NAME || 'Default Store View',
      default_display_currency_code: process.env.DEFAULT_DISPLAY_CURRENCY || 'GBP',
      locale: process.env.LOCALE || 'en_GB',
      header_logo_src: null,
      logo_width: null,
      logo_height: null,
      logo_alt: process.env.LOGO_ALT || 'Cartridge Save',
    },
  };
  $pinia.use(({ store }: PiniaPluginContext) => {
    if (store.$id !== 'magentoConfig') return;
    const storeConfig = ref(data?.storeConfig ?? {});

    // eslint-disable-next-line no-prototype-builtins
    if (!store.$state.hasOwnProperty('storeConfig')) {
      set(store.$state, 'storeConfig', storeConfig);
    } else {
      // eslint-disable-next-line no-param-reassign
      store.$state.storeConfig = storeConfig;
    }
  });
};

export default storeConfigPlugin;
