import { defineStore } from 'pinia';

import menuGql from '~/composables/useMenu/menu.gql';
import { CategoryResult } from '~/modules/GraphQL/types';

interface MenuState {
  rawCategories: CategoryResult | null
}

export const useMenuStore = defineStore('menu', {
  state: (): MenuState => ({
    rawCategories: null,
  }),
  actions: {
    async load() {
      const { data }: { data: { categories?: CategoryResult } } = await this.$nuxt.app.$vsf.$magento.api.customQuery({ query: menuGql });
      this.rawCategories = data?.categories ?? null;
    },
  },
  getters: {
    categories(state) {
      if (state.rawCategories === null) {
        return null;
      }

      return state.rawCategories?.items?.filter((category) => category.include_in_menu);
    },
    getCategoryById(state) {
      return (categoryId: number, parentId: number) => {
        if (state.rawCategories === null) {
          return null;
        }

        if (!parentId) {
          return state.rawCategories?.items?.[0]?.children.find((el) => el.id === categoryId);
        }

        return state.rawCategories?.items?.[0]?.children.find((parentEl) => parentEl.id === parentId)?.children?.find((el) => el.id === categoryId);
      };
    },
  },
});
