






































































import {
  defineComponent, ref,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { APP_ROUTER_PATHS } from '~/constants';

export default defineComponent({
  name: 'NotFound',
  components: { SvgImage },
  setup() {
    const searchInput = ref<string>('');

    const handleSearch = () => {
      if (searchInput.value) {
        window.location.replace(`/search?q=${searchInput.value}`);
      }
    };

    return {
      searchInput,
      homePath: APP_ROUTER_PATHS.home,
      contactPath: APP_ROUTER_PATHS.contact,
      handleSearch,
    };
  },
});
