import {
  fragmentPrice,
} from '~/customQueries/productDetails';

export default `
  query categoryMeta($filters: CategoryFilterInput) {
    categories(filters: $filters) {
      items {
        breadcrumbs {
          category_id
          category_name
          category_url_path
        }
        id
        uid
        level
        description
        printer_message
        canonical_url
        product_count
        name
        children_count
        title
        url_path
        path
        image
        additional_content
        meta_description
        meta_title
        printer_as_product {
          ...Price
          stock {
            is_in_stock
            qty
          }
          url_key
        }
        faq {
          category_data {
            product_url
            short_name
            yield_text
          }
          category_prefix
        }
        review_stars
        verdict_text
        review_text
        bestsellers {
          name
          url
          product_id
          price_incl_vat
          price_excl_vat
          thumbnail
          sku
        }
        children {
          title
          url_key
          printer_family
          product_count
          canonical_url
          id
        }
      }
    }
  }
  ${fragmentPrice}
`;
