import { defineStore } from 'pinia';

import { CategoryTree, CmsPage, ProductInterface } from '~/composables';
import { RouteData } from '~/middleware/url-resolver';
import { UseFacetSearchResult } from '~/modules/catalog/category/composables/useFacet';

interface PageState {
  routeData: RouteData;
  product?: ProductInterface;
  category?: CategoryTree;
  cmsPage?: CmsPage;
  initialData?: UseFacetSearchResult;
}

export const usePageStore = defineStore('page', {
  state: (): PageState => ({
    routeData: null,
    product: null,
    category: null,
    cmsPage: null,
    initialData: null,
  }),
});
