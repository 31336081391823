export default `
  query getCustomAttributeMetadata($attributes: [AttributeInput!]!) {
    customAttributeMetadata(attributes: $attributes) {
      items {
        attribute_code
        attribute_options {
          label
          value
        }
      }
    }
  }
`;
