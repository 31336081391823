













import {
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';

import { APP_ROUTER_PATHS } from '~/constants';

export default defineComponent({
  name: 'Logo',

  props: {
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: null,
    },
  },

  setup(props) {
    const style = computed(() => (props.width || props.height
      ? { width: `${props.width}px`, height: `${props.height}px` }
      : {}
    ));

    return {
      style,
    };
  },

  data() {
    return {
      routes: APP_ROUTER_PATHS,
    };
  },
});

