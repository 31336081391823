








































import {
  defineComponent,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { APP_ROUTER_PATHS, CONTACT_PHONE } from '~/constants';

export default defineComponent({
  name: 'TopBar',
  components: {
    SvgImage,
  },

  data() {
    return {
      routes: APP_ROUTER_PATHS,
      contactPhone: CONTACT_PHONE,
    };
  },
});

