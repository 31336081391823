










import { defineComponent } from '@nuxtjs/composition-api';

import HTMLContent from '~/components/common/HTMLContent.vue';

export default defineComponent({
  components: {
    HTMLContent,
  },

  props: {
    content: {
      type: String,
      default: '',
    },
  },
});
