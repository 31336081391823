








































import {
  defineComponent, onBeforeMount, ref,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import isServer from '~/helpers/isServer';

export default defineComponent({
  name: 'ZendeskButton',
  components: { SvgImage },
  props: {
    buttonType: {
      type: String as () => 'square' | 'default',
      default: 'default',
    },
  },
  setup(props) {
    const isLoadScript = ref<boolean>(false);
    const showButton = ref<boolean>(false);
    const buttonClass = ref<string>('');

    const loadZendeskChat = (callback) => {
      isLoadScript.value = true;

      // @ts-ignore eslint-disable-next-line
      window.zdonload = setInterval(() => {
        // @ts-ignore eslint-disable-next-line
        if (typeof zE !== 'undefined' && typeof global.zE.activate !== 'undefined') {
          // @ts-ignore eslint-disable-next-line
          clearInterval(Number(window.zdonload));
          callback();
        }
      }, 50, null);
    };

    const loadAndOpenZendeskChat = () => {
      if (props.buttonType === 'default') {
        buttonClass.value = 'pulse';
      }

      localStorage.setItem('ff_zd_hasOpened', 'true');

      loadZendeskChat(() => {
        window.setTimeout(() => {
          global.zE.activate();
          buttonClass.value = 'loaded';
          showButton.value = false;
        }, 1000);
      });
    };

    onBeforeMount(() => {
      if (!isServer) {
        global.zESettings = {
          webWidget: {
            chat: {
              connectOnPageLoad: false,
              suppress: false,
            },
            contactForm: {
              suppress: true,
            },
            talk: {
              suppress: true,
            },
          },
        };

        if (localStorage.getItem('ff_zd_hasOpened')) {
          loadZendeskChat(() => {});
          return;
        }

        showButton.value = true;
      }
    });

    return {
      isLoadScript,
      buttonClass,
      showButton,
      loadAndOpenZendeskChat,
    };
  },
});
