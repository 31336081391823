export const FILTERS_ORDER = [
  'hide_out_of_stock',
  'specification_brand',
  'specification_product_type',
  'price',
  'specification_brand_type',
  'all_in_one_functions',
  'duplex_functions',
  'standard_interfaces',
  'printing',
  'maximum_iso_a_series_paper',
  'colour',
  'printer_condition',
  'compatible_cartridges_available',
  'operating_systems_for_filters',
  'print_speed_filters',
];

export const IS_FILTERS_SORT_BY_CONFIG = true;
export const IS_FILTERS_SHOW_BY_CONFIG = true;

export const FORBIDDEN_SEARCH_FILTERS = [
  'price',
];
