








import {
  computed, defineComponent,
} from '@nuxtjs/composition-api';

import Delivery from '~/components/layout/Footer/Delivery/Delivery.vue';
import Links from '~/components/layout/Footer/Links/Links.vue';
import Payments from '~/components/layout/Footer/Payments/Payments.vue';
import { useCmsBlocksStore } from '~/stores/cmsBlocks';

export default defineComponent({
  components: {
    Delivery,
    Links,
    Payments,
  },

  setup() {
    const { getCmsBlockByIdentifier } = useCmsBlocksStore();

    const replacementContent = computed(() => getCmsBlockByIdentifier('delivery_replacement_footer'));
    const paymentContent = computed(() => getCmsBlockByIdentifier('footer_company_payment'));

    return {
      paymentContent,
      replacementContent,
    };
  },
});
