import { VsfContext } from '~/composables/context';
import { addBuyTogetherItemsToCartMutation } from '~/customQueries/cart';
import { Logger } from '~/helpers/logger';

/** Params object used to add items to a cart */
export declare type AddBuyTogetherProductsToCartInput = {
  cartId: string;
  mainSku: string;
  additionalSku: string;
};

export const addBuyTogetherItemsCommand = {
  execute: async (
    context: VsfContext,
    {
      mainProductSku,
      additionalProductSku,
      currentCart,
    },
  ) => {
    Logger.debug('[Magento]: Add buy together items to cart', {
      mainProductSku,
      additionalProductSku,
      currentCart,
    });

    const apiState = context.$magento.config.state;
    const cartId = apiState.getCartId();

    if (!(mainProductSku && additionalProductSku)) {
      return;
    }

    const simpleCartInput: AddBuyTogetherProductsToCartInput = {
      cartId,
      mainSku: mainProductSku,
      additionalSku: additionalProductSku,
    };

    const { data, errors } = await context.$magento.api.customMutation({
      mutation: addBuyTogetherItemsToCartMutation,
      mutationVariables: simpleCartInput,
    });

    Logger.debug('[Result]:', { data });

    // @ts-ignore
    if (!data?.buyTogetherAndSave) {
      throw new Error(String(errors[0].message));
    }
  },
};
