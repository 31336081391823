import { defineStore } from 'pinia';

import { AvailableStores } from '~/composables/types';
import { ShipmentConfig } from '~/composables/useShipmentDelivery';
import { Currency, StoreConfig } from '~/modules/GraphQL/types';

interface ConfigState {
  storeConfig: StoreConfig,
  stores: AvailableStores
  currency: Currency
  shipmentConfig: ShipmentConfig
}

export const useConfigStore = defineStore('magentoConfig', {
  state: (): ConfigState => ({
    storeConfig: {},
    stores: [],
    currency: {},
    shipmentConfig: {},
  }),
});
