import { computed } from '@nuxtjs/composition-api';
import { ProductPrices } from '@vue-storefront/magento-types';

import { MODAL_NAMES } from '~/components/common/SimpleModal/constants';
import { useTax, useUiState } from '~/composables';
import { useAddToCart } from '~/helpers/cart/addToCart';
import useCart from '~/modules/checkout/composables/useCart';
import { ProductInterface } from '~/modules/GraphQL/types';

import { UseSwitchAndSellHelpersInterface, UseSwitchAndSellInterface } from './useSwtichAndSell';

function useHelpers(): UseSwitchAndSellHelpersInterface {
  const { taxState, getCalculatedPrice } = useTax();

  const getCapacity = (yields) => {
    let sum = 0;

    yields.forEach((item) => {
      sum += !item.yield ? 0 : +item.yield;
    });

    return sum;
  };
  const getProductTierPrice = (product) => {
    const prices: ProductPrices = product.price;
    const originalPrice = getCalculatedPrice(prices.regularPrice);
    const finalPrice = getCalculatedPrice(prices.minimalPrice);

    if (product.special_price && +originalPrice > 0) {
      return +originalPrice;
    }

    if (product.special_price > 0) {
      return +finalPrice;
    }

    if (!product.special_price && Number.parseFloat(finalPrice) > 0) {
      return +finalPrice;
    }

    return 0;
  };

  const getFormatPrice = (priceObject) => (taxState.value ? +priceObject.priceInclTax : +priceObject.price);

  return {
    getCapacity,
    getProductTierPrice,
    getFormatPrice,
    getCalculatedPrice,
  };
}

export function useSwitchAndSell(product: ProductInterface): UseSwitchAndSellInterface {
  const { addItem, updateItemQty, removeItem } = useCart();
  const { getItemFromCart } = useAddToCart();
  const { toggleModal } = useUiState();

  const {
    getCapacity, getProductTierPrice, getFormatPrice, getCalculatedPrice,
  } = useHelpers();

  const altProduct = computed(() => product.alternative_product);

  const capacity = computed(() => getCapacity(product.yields));
  const altCapacity = computed(() => getCapacity(product.alternative_product.yields));
  const infoType = computed(() => {
    // Same capacity, expensive
    if (altCapacity.value === capacity.value) {
      return 1;
    }

    if (altCapacity.value < capacity.value) {
      return 2;
    }

    // higher capacity, less expensive
    if (getProductTierPrice(product) > getProductTierPrice(altProduct.value)) {
      return 3;
    }

    // higher capacity, more expensive
    return 4;
  });

  const diffPerPage = computed(() => {
    if (!product && !altProduct.value) {
      return 0;
    }

    const difference = Number.parseFloat(product.per_page_price) - Number.parseFloat(altProduct.value.per_page_price);
    const value = (difference / Number.parseFloat(product.per_page_price)) * 100;

    return Number.isNaN(value) ? 0 : Math.ceil(value);
  });

  const priceDifference = computed(() => getFormatPrice({
    price: altProduct.value.price_difference,
    priceInclTax: altProduct.value.price_difference_incl_tax,
  }));

  const infoContent = computed(() => ({
    diffPerPage: diffPerPage.value,
    pricePerPrint: product.per_page_price,
    pricePerPrintAlt: altProduct.value.per_page_price,
    pageDifference: capacity.value && altCapacity.value ? altCapacity.value - capacity.value : 0,
    brand: altProduct.value.brand,
    infoType: infoType.value,
    priceDifference: priceDifference.value,
    price: getCalculatedPrice(product.price.minimalPrice),
    altPrice: getCalculatedPrice(altProduct.value.price.minimalPrice),
    altCapacity: altCapacity.value,
    capacity: capacity.value,
  }));

  const toggleAfterAddToCartModal = (productToAdd: ProductInterface, quantity: number) => {
    toggleModal({
      identifier: MODAL_NAMES.afterAddToCart,
      props: {
        product: productToAdd,
        qty: quantity,
      },
    });
  };

  const handleSwitchProduct = async (quantity: number) => {
    const originCartItem = getItemFromCart(product);
    const newQty = originCartItem ? originCartItem.quantity - quantity : 0;

    toggleAfterAddToCartModal(altProduct.value, quantity);

    await (newQty > 0 ? updateItemQty({ product: originCartItem, quantity: newQty, customQuery: { updateCartItems: 'updateCartItems' } }) : removeItem({ product: originCartItem, customQuery: { removeItemFromCart: 'removeItemFromCart' } }));
    await addItem({ product: altProduct.value, quantity, customQuery: { addProductsToCart: 'addProductsToCart' } });
  };

  return {
    altProduct,
    infoContent,
    infoType,
    priceDifference,
    getCapacity,
    getProductTierPrice,
    handleSwitchProduct,
    toggleAfterAddToCartModal,
  };
}

export * from './useSwtichAndSell';
export default useSwitchAndSell;
