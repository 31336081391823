







































import { defineComponent } from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';

// @TODO: update animation name (enter -> enter-from) after when will be vue 3
export default defineComponent({
  name: 'Collapsible',
  components: {
    SvgImage,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    initState: {
      type: Boolean,
      default: false,
    },
    isRemoteOpen: {
      type: Boolean,
      default: false,
    },
    disableAction: {
      type: Boolean,
      default: false,
    },
    showArrow: {
      type: Boolean,
      default: false,
    },
    component: {
      type: String,
      default: 'button',
    },
    triggerClasses: {
      type: String,
      default: '',
    },
    arrowColor: {
      type: String,
      default: '#333333',
    },
  },

  data() {
    return {
      open: this.initState,
    };
  },

  methods: {
    onClick() {
      if (this.disableAction) {
        return;
      }

      this.open = !this.open;
    },

    enter(element: HTMLElement) {
      const el = element;
      const computedStyles = getComputedStyle(element);

      el.style.width = computedStyles.width;
      el.style.position = 'absolute';
      el.style.visibility = 'hidden';
      el.style.height = 'auto';

      const { height } = computedStyles;

      el.style.width = null;
      el.style.position = null;
      el.style.visibility = null;
      el.style.height = '0';

      // Workaround to fix animation issue in Firefox
      requestAnimationFrame(() => requestAnimationFrame(() => {
        el.style.height = height;
      }));
    },
    afterEnter(element: HTMLElement) {
      const el = element;

      el.style.height = 'auto';
    },
    leave(element: HTMLElement) {
      const el = element;

      el.style.height = getComputedStyle(element).height;

      requestAnimationFrame(() => requestAnimationFrame(() => {
        el.style.height = '0';
      }));
    },
  },
});
