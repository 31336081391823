import {
  fragmentCashback, fragmentPrice, fragmentProduct, fragmentStock,
} from '~/customQueries/productDetails';

export default `
  query productListing($filters: CategoryFilterInput) {
    categories(filters: $filters) {
      items {
        products {
          items {
            group
            price_tiers {
              quantity
              final_price {
                value
              }
              final_price_incl_tax {
                value
              }
            }
            yields {
              color
              swatches
              yield_text
              yield
            }
            return_program_hover_over
            return_program_hover_show
            cartridge_condition
            sku_pack_component
            due_in_date
            __typename
            ...BaseProduct
            ...Cashback
            ...Price
            ...Stock
          }
        }
      }
    }
  }
  ${fragmentProduct}
  ${fragmentPrice}
  ${fragmentCashback}
  ${fragmentStock}
`;
