import { ref } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';

import { useContent } from '~/composables';
import { CmsBlock } from '~/modules/GraphQL/types';

interface CmsBlocksState {
  cmsBlocks: {
    [identifier: string]: string,
  }
}

export const useCmsBlocksStore = defineStore('cmsBlocks', {
  state: (): CmsBlocksState => ({
    cmsBlocks: {},
  }),
  actions: {
    async load(identifiers: string[], forceUpdate = false) {
      const {
        loadBlocks,
      } = useContent();
      const blocks = ref<CmsBlock[]>([]);
      const newIdentifiers = identifiers.filter((id) => forceUpdate || !Object.keys(this.cmsBlocks).includes(id)).filter(Boolean);

      if (newIdentifiers.length === 0) {
        return;
      }

      newIdentifiers.forEach((id) => {
        this.cmsBlocks[id] = '';
      });

      blocks.value = await loadBlocks({ identifiers: newIdentifiers });

      if (blocks.value.length > 0) {
        const newBlocks = Object.fromEntries(blocks.value.filter(Boolean).map(
          (block) => [block.identifier, block.content],
        ));

        this.cmsBlocks = {
          ...this.cmsBlocks,
          ...newBlocks,
        };
      }
    },
  },
  getters: {
    getCmsBlockByIdentifier(state) {
      return (identifier) => state.cmsBlocks[identifier];
    },
  },
});
