export const IMAGE_CONFIG = {
  baseUrl: '/img/',
  useSpecificImagePaths: true,
  paths: {
    product: '/catalog/product',
    category: '/catalog/category',
    brandLogo: '/wysiwyg/thumbnail/brand',
  },
  productPlaceholder: '/assets/placeholder.png',
  sizes: {
    gallery: {
      width: 400,
      height: 400,
      zoom: {
        width: 600,
        height: 744,
      },
      bigImage: 1024,
    },
    thumbnails: {
      width: 310,
      height: 300,
    },
    brandLogo: {
      width: 120,
      height: 80,
    },
  },
};
