import { computed, useRoute } from '@nuxtjs/composition-api';

import { APP_ROUTER_PATHS } from '~/constants';

import { UsePageHelpersInterface } from './usePageHelpers';

export function usePageHelpers(): UsePageHelpersInterface {
  const route = useRoute();

  const isPrinterPage = computed(() => route.value.path === APP_ROUTER_PATHS.printers || route.value.name === 'printers');
  const isSearchPage = computed(() => route.value.name === 'search');

  return {
    isPrinterPage,
    isSearchPage,
  };
}

export * from './usePageHelpers';
export default usePageHelpers;
