

















import {
  defineComponent, onMounted,
  ref,
  useFetch,
} from '@nuxtjs/composition-api';

import VatToggler from '~/components/VatToggler/VatToggler.vue';
import {
  useCart,
} from '~/composables';
import useMenu from '~/composables/useMenu';
import { useUser } from '~/modules/customer/composables/useUser';
import type { CategoryTree } from '~/modules/GraphQL/types';

import { DesktopNavigation, MobileNavigation } from './Header/Navigation';
import TopBar from './Header/TopBar/TopBar.vue';
import TopNavigation from './Header/TopNavigation/TopNavigation.vue';
import Usps from './Header/Usps/Usps.vue';

export default defineComponent({
  components: {
    TopBar,
    TopNavigation,
    DesktopNavigation,
    Usps,
    VatToggler,
    MobileNavigation,
  },
  setup() {
    const { isAuthenticated } = useUser();
    const { categories: menuList, load: menuListLoad } = useMenu();
    const { load: loadCart } = useCart();
    const categoryTree = ref<CategoryTree[]>([]);

    useFetch(async () => {
      await menuListLoad();

      categoryTree.value = menuList.value;
    });

    onMounted(async () => {
      await loadCart();
    });

    return {
      categoryTree,
      isAuthenticated,
    };
  },
});
