const rootCategoryId = process.env.VSF_MAGENTO_ROOT_CATEGORY_ID || '207008';
const fragmentCategory = `
  fragment CategoryFields on CategoryTree {
    name
    uid
    id
    url_path
    url_suffix
    include_in_menu
    is_hidden_in_nav
    product_count
    children_count
    level
  }
`;

export default `
  query categoryList {
    categories(filters: {parent_id: {eq: ${rootCategoryId}}}) {
      items {
        ...CategoryFields
        children {
          ...CategoryFields
          children {
            ...CategoryFields
          }
        }
      }
    }
  }
  ${fragmentCategory}
`;
