










import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Overlay',
  props: {
    transition: {
      type: String,
      default: 'fade',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      default: 3,
    },
  },
});
