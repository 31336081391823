import {
  computed,
} from '@nuxtjs/composition-api';

import { useMagentoConfiguration } from '~/composables';
import { useTaxStore } from '~/modules/catalog/pricing/useTaxPrice';
import { Price } from '~/modules/GraphQL/types';

import type {
  UseTaxInterface,
} from './useTax';

const VAT_RATE = 1.2;

export function useTax(): UseTaxInterface {
  const taxStore = useTaxStore();
  const { currentCurrencySymbol } = useMagentoConfiguration();

  const taxState = computed(() => taxStore.tax);
  const vatLabel = computed(() => (taxState.value ? 'inc VAT' : 'ex VAT'));

  const getCalculatedPrice = (price: Price) => {
    const value = taxState.value ? price?.amount?.value : ((price?.amount?.value || 0) - (price.adjustments?.[0]?.amount?.value || 0));

    return value.toFixed(2);
  };

  const rawPriceCalculation = (price: number) => (taxState.value ? (price * VAT_RATE).toFixed(2) : price.toFixed(2));
  const rawPriceWithCurrency = (price: number) => {
    const priceValue = taxState.value ? (price * VAT_RATE) : price;

    return `${currentCurrencySymbol.value}${Number.parseInt(`${priceValue}`, 10)}`;
  };

  const setTax = (value: boolean) => taxStore.setTaxFlag(value);

  return {
    taxState,
    vatLabel,
    setTax,
    getCalculatedPrice,
    rawPriceCalculation,
    rawPriceWithCurrency,
  };
}

export * from './useTax';

export default useTax;
