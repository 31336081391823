export interface SortingModel {
  selected: string,
  options: SortingOption[]
}

export interface SortingOption {
  label: string,
  value: SortingOptionsValuesEnum
}

export enum SortingOptionsValuesEnum {
  DEFAULT = '',
  PRICE_ASC = 'price_ASC',
  PRICE_DESC = 'price_DESC',
}

export const sortingOptions: SortingOption[] = [
  {
    label: 'Sort: Relevance',
    value: SortingOptionsValuesEnum.DEFAULT,
  },
  {
    label: 'Price: low to high',
    value: SortingOptionsValuesEnum.PRICE_ASC,
  },
  {
    label: 'Price: high to low',
    value: SortingOptionsValuesEnum.PRICE_DESC,
  },
];
