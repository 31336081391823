import useAttributes from '~/composables/useAttributes';
import { getBrandBulletTitle, getCompatibilityBrand } from '~/helpers/productHelpers';
import { ProductInterface } from '~/modules/GraphQL/types';

export const BRAND_TEMPLATES = [
  {
    brand: '',
    condition: 'remanufactured',
    packSize: 'single',
    group: 'maintenance',
    ranking: '2',
    article: 'Made using recycled <Manufacturer> parts, this maintenance kit is a direct replacement for the <Manufacturer> version. Manufactured to strict quality standards, our premium maintenance kits are very reliable — in fact, we use them in our offices and recommend them to friends, family and other businesses.',
  },
  {
    brand: '',
    condition: 'remanufactured',
    packSize: 'single',
    group: 'fuser kit',
    ranking: '2',
    article: 'Made using recycled <Manufacturer> parts, this fuser kit is a direct replacement for the <Manufacturer> version. Manufactured to strict quality standards, our premium fuser kits are very reliable — in fact, we use them in our offices and recommend them to friends, family and other businesses.',
  },
  {
    brand: '',
    condition: 'remanufactured',
    packSize: 'single',
    group: 'drum',
    ranking: '2',
    article: 'Made using recycled <Manufacturer> parts, this image drum is a direct replacement for the <Manufacturer> version. Manufactured to strict quality standards, our premium drums produce high quality prints and are very reliable — in fact, we use them in our offices and recommend them to friends, family and other businesses.',
  },
  {
    brand: '',
    condition: 'remanufactured',
    packSize: 'single',
    group: 'cartridge',
    ranking: '2',
    article: 'Made using recycled <Manufacturer> cartridges, this cartridge is a direct replacement for the <Manufacturer> version. Manufactured to strict quality standards, our premium cartridges produce high quality prints and are very reliable — in fact, we use them in our offices and recommend them to friends, family and other businesses.',
  },
  {
    brand: '',
    condition: 'remanufactured',
    packSize: 'multiple',
    group: 'drum',
    ranking: '2',
    article: 'Made using recycled <Manufacturer> parts, these image drums are a direct replacement for the <Manufacturer> versions. Manufactured to strict quality standards, our premium drum cartridges produce high quality prints and are very reliable — in fact, we use them in our offices and recommend them to friends, family and other businesses.',
  },
  {
    brand: '',
    condition: 'remanufactured',
    packSize: 'multiple',
    group: 'cartridge',
    ranking: '2',
    article: 'Made using recycled <Manufacturer> cartridges, these cartridges are a direct replacement for the <Manufacturer> versions. Manufactured to strict quality standards, our premium cartridges produce high quality prints and are very reliable — in fact, we use them in our offices and recommend them to friends, family and other businesses.',
  },
  {
    brand: 'pitney bowes alternative royal mail approved',
    condition: '',
    packSize: '',
    group: '',
    ranking: '1',
    article: 'This cartridge is a direct replacement for the Pitney Bowes version. Fully approved by Royal Mail and CE marked, the inks in these cartridges have passed stringent quality tests to ensure they are suitable for the UK postal system.',
  },
  {
    brand: 'pitney bowes alternative',
    condition: '',
    packSize: '',
    group: '',
    ranking: '1',
    article: 'This cartridge is a direct replacement for the Pitney Bowes version. Manufactured to strict quality standards, these premium cartridges produce high quality franking prints and are very reliable.',
  },
  {
    brand: 'neopost alternative royal mail approved',
    condition: '',
    packSize: '',
    group: '',
    ranking: '1',
    article: 'This cartridge is a direct replacement for the Neopost version. Fully approved by Royal Mail and CE marked, the inks in these cartridges have passed stringent quality tests to ensure they are suitable for the UK postal system.',
  },
  {
    brand: '',
    condition: 'compatible',
    packSize: 'single',
    group: 'drum',
    ranking: '2',
    article: 'This drum is a direct replacement for the <Manufacturer> version. Manufactured to strict quality standards, our premium drums produce high quality prints and are very reliable — in fact, we use them in our offices and recommend them to friends, family and other businesses.',
  },
  {
    brand: '',
    condition: 'compatible',
    packSize: 'single',
    group: 'cartridge',
    ranking: '2',
    article: 'These cartridges \nare a direct replacement for the <Manufacturer> version. \nManufactured to strict quality standards, our premium cartridges \nproduce high-quality prints and are very reliable.',
  },
  {
    brand: '',
    condition: 'compatible',
    packSize: 'multiple',
    group: 'cartridge',
    ranking: '2',
    article: 'These cartridges \nare a direct replacement for the <Manufacturer> versions. \nManufactured to strict quality standards, our premium cartridges \nproduce high-quality prints and are very reliable.',
  },
];

export interface BrandTooltipHelpers {
  getBrandContent: (product: ProductInterface, title: string) => { text: string, title: string },
}

export const getBrandGroup = (productTypeId: number) => {
  const { getOptionLabel } = useAttributes();

  const group = getOptionLabel('specification_product_type', productTypeId);

  if (group && (group?.includes('Image Drum') || group?.includes('Drum Kit'))) {
    return 'drum';
  }

  if (group === 'Fuser Kit') {
    return 'fuser kit';
  }

  if (group === 'Laser Maintenance') {
    return 'maintenance';
  }

  return 'cartridge';
};

export const getTitle = (product: ProductInterface, domain?: string) => {
  const { getOptionLabel } = useAttributes();

  if (product.compatibility_list?.length) {
    return getCompatibilityBrand(product.compatibility_list, domain);
  }

  const specificationBrand = getOptionLabel('specification_brand', product.specification_brand);

  return specificationBrand.replace('Cartridge Save ', '').replace('Compatible ', '');
};

export const getBrandContent = (product: ProductInterface, cartridgeCondition: string, domain?: string) => {
  if (!product) return null;

  const { getOptionLabel } = useAttributes();

  const brandTitle = getTitle(product, domain);
  const title = getBrandBulletTitle(cartridgeCondition, product.manufacturer_anchor, brandTitle);
  const condition = getOptionLabel('cartridge_condition', product.cartridge_condition);
  const group = getBrandGroup(product.specification_product_type);

  const brandContent = {
    text: null,
    title,
  };

  const brand = product.manufacturer_anchor ? product.manufacturer_anchor.replace('Genuine', 'Original') : '';
  const brandRegex = /<Manufacturer>/g;
  const brandTemplates = BRAND_TEMPLATES.filter((item) => item.brand === brand);

  if (brandTemplates.length > 0) {
    brandContent.text = brandTemplates[brandTemplates.length - 1].article.replace(brandRegex, brandTitle);
  } else {
    const packSize = !product.name.includes('pack') ? 'single' : 'multiple';
    // eslint-disable-next-line max-len
    const brandTemplatesByCondition = BRAND_TEMPLATES.filter((item) => item.condition === condition && item.packSize === packSize && item.group === group);

    if (brandTemplatesByCondition.length > 0) {
      brandContent.text = brandTemplatesByCondition[0].article.replace(brandRegex, brandTitle);
    }
  }

  return brandContent;
};

const brandTooltipHelpers: BrandTooltipHelpers = {
  getBrandContent,
};

export default brandTooltipHelpers;
