















import {
  computed, defineComponent,
  onMounted, ref,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SwitchButton',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['state-change'],
  setup(props, { emit }) {
    const position = ref<number>(0);
    const state = ref<boolean>(false);
    const xPosition = computed(() => `${(position.value / 100) * 100}%`);

    const toggle = (stateValue) => {
      state.value = stateValue;
      position.value = !stateValue
        ? 0
        : 100;
    };

    const onClick = () => {
      toggle(!state.value);
      emit('state-change', state.value);
    };

    onMounted(() => {
      toggle(props.value);
    });

    return {
      state,
      xPosition,
      toggle,
      onClick,
    };
  },
});
