export function smoothScrollToElement(selector: string, timeout: number = 400) {
  const element = document.querySelector(selector);

  if (!element) return;

  setTimeout(() => {
    element.scrollIntoView({
      behavior: 'smooth',
    });
  }, timeout);
}
