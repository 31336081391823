export const MODAL_NAMES = {
  lightboxImage: 'LIGHTBOX_IMAGE_MODAL',
  productPack: 'PRODUCT_PACK_MODAL',
  protection: 'PROTECTION_MODAL',
  manufacturerInfo: 'MANUFACTURER_INFO_MODAL',
  info: 'INFO_MODAL',
  warranty: 'WARRANTY_MODAL',
  outOfStock: 'OUT_OF_STOCK_MODAL',
  switchAndSell: 'SWITCH_AND_SELL_MODAL',
  afterAddToCart: 'AFTER_ADD_TO_CART_MODAL',
  newVisibility: 'NEW_VISIBILITY_MODAL',
  categoryImage: 'CATEGORY_IMAGE_MODAL',
  brandLink: 'BRAND_LINK_MODAL',
};
