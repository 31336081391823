import gql from 'graphql-tag';

export default gql`
  query productReview($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, sort: $sort) {
      items {
        reviews(pageSize: $pageSize, currentPage: $currentPage) {
          items {
            ratings_breakdown {
              value
            }
            nickname
            text
            created_at
          }
        }
      }
    }
  }
`;
