import {
  computed,
  onMounted, reactive, useContext,
} from '@nuxtjs/composition-api';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import merge from 'lodash-es/merge';

import { useConfig } from '~/composables';
import {
  getBeforeCutOffTime, getCutOffTime, getFormattedDate, isHolidays,
} from '~/composables/useShipmentDelivery/helpers';

import {
  ShipmentDeliverySettings, UseShipmentDeliveryInterface,
} from './useShipmentDelivery';

export function useShipmentDelivery(cutOffTime: string = ''): UseShipmentDeliveryInterface {
  const { shipmentConfig } = useConfig();
  const { app: { i18n } } = useContext();

  const settings = reactive<ShipmentDeliverySettings>({
    headerMessage: 'FREE next-day delivery on orders over £30',
    xMasMessage: 'Christmas delivery info',

    dispatchDate: null,

    deliveryUrl: '/i/free-delivery.html',
    footerDateFormat: 'dddd Do MMMM',
    uspDateFormat: 'dddd',
    nextDeliveryOffset: 0,
  });
  const dispatchTextToday = computed(() => i18n.t('when you order before {value}', { value: getCutOffTime(cutOffTime) }));
  const holidays = computed(() => merge([], shipmentConfig.holidays));

  const nextWorkingDay = (today) => {
    let currentOffset = 0;
    let totalOffset = 0;
    let day = today;

    while (currentOffset !== settings.nextDeliveryOffset) {
      day = day.add(1, 'day');

      if (!isHolidays(day, holidays.value)) {
        currentOffset++;
      }

      totalOffset++;
    }

    settings.nextDeliveryOffset = totalOffset;

    return day;
  };

  const showChristmasDelivery = computed(() => shipmentConfig.show_christmas_delivery);
  const isChristmasEnabled = computed(() => shipmentConfig.christmas_enabled);

  const productDeliveryText = computed(() => {
    const now = dayjs();
    const tomorrow = now.add(1, 'day');

    if (isHolidays(now, holidays.value)) {
      return i18n.t('Order now for dispatch on {value}', { value: getFormattedDate(settings.dispatchDate, settings.uspDateFormat) });
    }

    if (!getBeforeCutOffTime(now, cutOffTime) && !isHolidays(tomorrow, holidays.value)) {
      return i18n.t('Order now for dispatch tomorrow');
    }

    return dispatchTextToday.value;
  });

  const setEstimate = () => {
    if (showChristmasDelivery.value) {
      settings.dispatchDate = shipmentConfig.delivery_date_after_christmas || '';
    }

    const now = dayjs();

    settings.nextDeliveryOffset = !getBeforeCutOffTime(now, cutOffTime) || isHolidays(now, holidays.value) ? 1 : 0;

    const nextDate = nextWorkingDay(now);

    if (nextDate) {
      settings.dispatchDate = nextDate;
    }
  };

  onMounted(() => {
    dayjs.extend(advancedFormat);
    setEstimate();
  });

  return {
    headerMessage: settings.headerMessage,
    xMasMessage: settings.xMasMessage,
    dispatchTextToday,
    showChristmasDelivery,
    isChristmasEnabled,
    productDeliveryText,
  };
}

export * from './useShipmentDelivery';
export default useShipmentDelivery;
