import { computed } from '@nuxtjs/composition-api';

import { useMagentoConfiguration } from '~/composables';
import { getBrandTitle, getModelName } from '~/helpers/productHelpers';
import { ProductInterface } from '~/modules/GraphQL/types';

import { UseCompatibleTitleInterface } from './useCompatibleTitle';

export function useCompatibleTitle(product: ProductInterface): UseCompatibleTitleInterface {
  const { magentoBaseUrl } = useMagentoConfiguration();

  const brandTitle = computed(() => getBrandTitle(product.compatibility_list, magentoBaseUrl.value));
  const compatibleTitle = computed(() => {
    const brand = brandTitle.value;
    const model = product ? getModelName(brandTitle.value, product.short_name) : '';

    return `${model?.toLowerCase().indexOf(brand.toLowerCase()) >= 0 ? '' : brand} ${model}`;
  });

  return {
    brandTitle,
    compatibleTitle,
  };
}
