import locale77427e78 from '../../lang/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"default","numberFormats":{"default":{"currency":{"style":"currency","currency":"GBP","currencyDisplay":"symbol"}},"german":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"default","file":"en.js","iso":"en","defaultCurrency":"GBP"},{"code":"german","file":"de.js","iso":"de_DE","defaultCurrency":"EUR"}],
  defaultLocale: "default",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://www.cartridgesave.co.uk",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "US",
  seo: true,
  normalizedLocales: [{"code":"default","file":"en.js","iso":"en","defaultCurrency":"GBP"},{"code":"german","file":"de.js","iso":"de_DE","defaultCurrency":"EUR"}],
  localeCodes: ["default","german"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "a",
  15: "n",
  16: "g",
  17: "/",
  18: "e",
  19: "n",
  20: ".",
  21: "j",
  22: "s",
  23: "\"",
  24: ",",
  25: "\"",
  26: "d",
  27: "e",
  28: ".",
  29: "j",
  30: "s",
  31: "\"",
  32: ":",
  33: "\"",
  34: ".",
  35: ".",
  36: "/",
  37: "l",
  38: "a",
  39: "n",
  40: "g",
  41: "/",
  42: "d",
  43: "e",
  44: ".",
  45: "j",
  46: "s",
  47: "\"",
  48: "}",
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'de.js': () => import('../../lang/de.js' /* webpackChunkName: "lang-de.js" */),
}
