import dayjs from 'dayjs';

function getHoursAndMinute(timeStr = '') {
  const [hours, minutes] = timeStr.split(':')?.map(Number) || [];

  return {
    hours,
    minutes,
  };
}

export function getCutOffTime(time: string): string {
  let ret = '';
  const cutOffTime = time;

  if (!cutOffTime) {
    return ret;
  }

  const data = getHoursAndMinute(cutOffTime);
  let { hours } = data;
  let pm = false;

  if (hours >= 12) {
    pm = true;
  }

  if (hours > 12) {
    hours -= 12;
  }

  ret = (data?.minutes > 0) ? `${hours}:${data?.minutes}` : `${hours}`;

  return pm ? `${ret}pm` : `${ret}am`;
}

export function getFormattedDate(date, fmt) {
  if (date) {
    return date.format(fmt);
  }

  return '';
}

export function getBeforeCutOffTime(today, cutOffTime = '') {
  const todayValue = today || dayjs();

  if (!cutOffTime) {
    return true;
  }

  const { hours, minutes } = getHoursAndMinute(cutOffTime);

  if (todayValue.format('HH') > hours) {
    return false;
  }

  return !(todayValue.format('HH') === hours && todayValue.format('mm') >= minutes);
}

export function isHolidays(day, holidays) {
  return holidays.includes(day.format('YYYY-MM-DD'));
}
