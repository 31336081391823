import { Context as NuxtContext } from '@nuxt/types';
import { merge } from 'lodash-es';

import { Logger } from '~/helpers/logger';

import { getQueryData, isOverrideQuery } from './_customUtils';

export type ApiClientMethod = (...args: any) => Promise<any>;

interface CreateProxiedApiParams {
  nuxtCtx: NuxtContext;
  givenApi: Record<string, ApiClientMethod>;
  client: any;
  tag: string;
}

export const createProxiedApi = ({
  givenApi, client, tag, nuxtCtx,
}: CreateProxiedApiParams) => new Proxy(givenApi, {
  get: (target, prop, receiver) => {
    const functionName = String(prop);
    if (Reflect.has(target, functionName)) {
      return Reflect.get(target, prop, receiver);
    }
    const isMiddleware = process.env.VSF_MAGENTO_DISABLE_MIDDLEWARE !== '1';
    const isCustomQuery = functionName === 'customQuery' || isOverrideQuery(functionName);
    const url = isMiddleware || (!isMiddleware && !isCustomQuery)
      ? `/${tag}/${functionName}`
      : process.env.VSF_MAGENTO_GRAPHQL_URL;

    // eslint-disable-next-line @typescript-eslint/require-await
    return async (...args) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const queryArgs = !isMiddleware ? getQueryData(functionName, args) || args[0] : args[0];

      const query = queryArgs?.query?.trimStart().replace(/  +/g, ' ').replace(/\n/g, '');
      const body = { query, ...(queryArgs?.queryVariables ? { variables: JSON.stringify(queryArgs.queryVariables) } : {}) };
      const customerToken = nuxtCtx.$vsf?.$magento?.config?.state?.getCustomerToken() ?? '';

      const preparedClient = !isMiddleware && isCustomQuery
        ? client.get(`${url}?${new URLSearchParams(body)}`, {
          headers: {
            ...(customerToken && { Authorization: `Bearer ${customerToken}` }),
          },
        })
        : client.post(url, args);

      return preparedClient
        .then((r) => r.data)
        .catch((err) => {
          Logger.debug(err);
          nuxtCtx.error({ statusCode: err.statusCode, message: err });

          return {};
        });
    };
  },
});

export const getCookies = (context: NuxtContext) => context?.req?.headers?.cookie ?? '';

export const getIntegrationConfig = (context: NuxtContext, configuration: any) => {
  const cookie = getCookies(context);
  return merge({
    axios: {
      headers: {
        ...(cookie ? { cookie } : {}),
      },
      withCredentials: true,
    },
  }, configuration);
};
