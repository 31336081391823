import { DocumentNode } from 'graphql';
import { print } from 'graphql/language/printer';

import { cmsBlocksQuery, cmsPageQuery, routeQuery } from '~/customQueries/magento';
import customProductDetailsQuery from '~/customQueries/productDetails';
import customProductReviewQuery from '~/customQueries/productReview';

type CustomQuery = {
  [name: string]: {
    query: DocumentNode,
    variables?: string[],
    outputVariable?: string,
    isArrayOutput?: boolean,
  }
};

const CUSTOM_QUERIES: CustomQuery = {
  productDetails: {
    query: customProductDetailsQuery,
    variables: ['filter'],
  },
  productReview: {
    query: customProductReviewQuery,
    variables: ['filter', 'pageSize'],
  },
  cmsBlocks: {
    query: cmsBlocksQuery,
    outputVariable: 'identifiers',
    isArrayOutput: true,
  },
  cmsPage: {
    query: cmsPageQuery,
    outputVariable: 'identifier',
  },
  route: {
    query: routeQuery,
    outputVariable: 'url',
  },
};

export function isOverrideQuery(functionName: string) {
  return Object.keys(CUSTOM_QUERIES).includes(functionName);
}

export function getQueryData(functionName: string, args: object[]) {
  if (!isOverrideQuery(functionName)) {
    return null;
  }

  const currentQuery = CUSTOM_QUERIES[functionName];

  const result = {
    query: print(currentQuery.query),
    queryVariables: {},
  };

  if (currentQuery.outputVariable) {
    result.queryVariables[currentQuery.outputVariable] = currentQuery.isArrayOutput ? [args[0]].flat() : args[0];

    return result;
  }

  Object.keys(args?.[0]).forEach((variable) => {
    if (currentQuery.variables.includes(variable)) {
      result.queryVariables[variable] = args[0][variable];
    }
  });

  return result;
}
