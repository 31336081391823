export const ShipmentConfigQuery = `
  query shipmentConfig {
    shipmentConfig {
      cut_off_time
      delivery_estimate
      show_christmas_delivery
      delivery_date_after_christmas
      holidays
      christmas_enabled
    }
  }
`;

export default ShipmentConfigQuery;
