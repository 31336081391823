export default {
  default: {
    organization: {
      name: 'Cartridge Save',
      url: 'https://www.cartridgesave.co.uk/',
      logo: 'https://www.cartridgesave.co.uk/media/cartridge-save-logo.png',
      description: 'Toner cartridge supplier',
      sameAs: ['https://twitter.com/CartridgeSave', 'https://www.facebook.com/cartsave/', 'https://www.linkedin.com/company/cartridge-save', 'https://uk.trustpilot.com/review/www.cartridgesave.co.uk', 'https://www.crunchbase.com/organization/cartridge-save'],
    },
    breadcrumbs: {
      itemListElement: [],
    },
    product: {
      reviewMaxSize: 5,
    },
  },
};
