export const getFullUrl = (url: string): string => `${process.env.VSF_MAGENTO_BASE_URL}${url}`;

export const getParameterByName = (name: string, url: string): string | null => {
  const param = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${param}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const updateURLParam = (url: string, param: string, value: string | number): string => {
  const urlObj = new URL(url);

  if (urlObj.searchParams.has(param)) {
    urlObj.searchParams.set(param, `${value}`);
  } else if (!value) {
    urlObj.searchParams.delete(param);
  } else {
    urlObj.searchParams.append(param, `${value}`);
  }

  return urlObj.toString();
};
