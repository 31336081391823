


































import {
  defineComponent,
} from '@nuxtjs/composition-api';

import HTMLContent from '~/components/common/HTMLContent.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import useShipmentDelivery from '~/composables/useShipmentDelivery';
import { APP_ROUTER_PATHS } from '~/constants';

export default defineComponent({
  name: 'Delivery',
  components: {
    SvgImage,
    HTMLContent,
  },

  props: {
    content: {
      type: String,
      default: '',
    },
  },

  setup() {
    const { headerMessage } = useShipmentDelivery();

    return {
      headerMessage,
    };
  },

  data() {
    return {
      freeDeliveryPath: APP_ROUTER_PATHS.freeDelivery,
    };
  },
});

