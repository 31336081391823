


















import { defineComponent } from '@nuxtjs/composition-api';

import { APP_ROUTER_PATHS } from '~/constants';

import Column from './components/Column/Column.vue';
import LinksList from './components/List/List.vue';

export default defineComponent({
  components: {
    Column,
    LinksList,
  },

  data() {
    return {
      columns: [
        {
          title: 'Customer services',
          links: [
            {
              path: APP_ROUTER_PATHS.account,
              value: 'Account',
              isExternal: true,
            },
            {
              path: APP_ROUTER_PATHS.help,
              value: 'Help',
              isExternal: true,
            },
            {
              path: APP_ROUTER_PATHS.freeDelivery,
              value: 'Delivery',
            },
            {
              path: APP_ROUTER_PATHS.returns,
              value: 'Returns',
              isExternal: true,
            },
            {
              path: APP_ROUTER_PATHS.contact,
              value: 'Contact us',
            },
          ],
        },
        {
          title: 'Company info',
          links: [
            {
              path: APP_ROUTER_PATHS.aboutUs,
              value: 'About us',
            },
            {
              path: APP_ROUTER_PATHS.recycling,
              value: 'Recycling',
            },
            {
              path: APP_ROUTER_PATHS.resources,
              value: 'Resources',
              isExternal: true,
            },
          ],
        },
        {
          title: 'Our guarantees',
          links: [
            {
              path: APP_ROUTER_PATHS.moneyBack,
              value: '100% satisfaction',
            },
            {
              path: APP_ROUTER_PATHS.lowPrice,
              value: 'Lowest online price',
            },
          ],
        },
        {
          title: 'Payment',
          links: [
            {
              path: APP_ROUTER_PATHS.businessAccount,
              value: 'Open a business account',
              isExternal: true,
            },
            {
              path: APP_ROUTER_PATHS.authorities,
              value: 'Schools and local authorities',
            },
          ],
        },
      ],
    };
  },
});
