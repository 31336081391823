

















































































import { computed, defineComponent } from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import useShipmentDelivery from '~/composables/useShipmentDelivery';
import { APP_ROUTER_PATHS } from '~/constants';
import { useCmsBlocksStore } from '~/stores/cmsBlocks';

export default defineComponent({
  name: 'Usps',
  components: {
    SvgImage,
  },
  setup() {
    const { getCmsBlockByIdentifier } = useCmsBlocksStore();
    const {
      headerMessage, xMasMessage, showChristmasDelivery, isChristmasEnabled,
    } = useShipmentDelivery();

    const replacementContent = computed(() => getCmsBlockByIdentifier('delivery_replacement_header'));

    return {
      headerMessage,
      xMasMessage,
      showChristmasDelivery,
      isChristmasEnabled,
      replacementContent,
      freeDeliveryLink: APP_ROUTER_PATHS.freeDelivery,
    };
  },
});
