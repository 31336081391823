





































import {
  computed, defineComponent, ref, useRoute, watch,
} from '@nuxtjs/composition-api';

import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import SvgImage from '~/components/General/SvgImage.vue';
import CartIcon from '~/components/layout/Header/MicrocartIcon/CartIcon.vue';
import { useUiState } from '~/composables';
import useMenu from '~/composables/useMenu';
import { useUser } from '~/modules/customer/composables/useUser';

const TAB_NAMES = {
  menu: 'menu',
  search: 'search',
  account: 'account',
};

const EXCLUDED_OVERLAY = new Set([TAB_NAMES.search]);

export default defineComponent({
  name: 'MobileNavigation',
  components: {
    SvgImage,
    'tab-menu': () => import('./Tabs/Menu/Menu.vue'),
    'tab-search': () => import('./Tabs/Search/Search.vue'),
    'tab-account': () => import('./Tabs/Account/Account.vue'),
    CartIcon,
  },
  directives: { clickOutside },
  setup() {
    const route = useRoute();
    const {
      setOverlay,
      setMobileNav,
      isMobileNav,
    } = useUiState();
    const { isAuthenticated } = useUser();
    const categories = useMenu();

    const currentTab = ref<string>(TAB_NAMES.menu);

    const openTab = (tabName: string, onClick: () => {}) => {
      setOverlay(!EXCLUDED_OVERLAY.has(tabName));
      onClick?.();

      if (tabName === currentTab.value && isMobileNav.value) {
        setOverlay(false);
        setMobileNav(false);
      } else {
        setMobileNav(true);
      }

      currentTab.value = tabName;
    };

    const handleFocusOut = () => {
      if (isMobileNav.value) {
        setOverlay(false);
        setMobileNav(false);
      }
    };

    const loadCategoryMenu = async () => {
      if (categories.categories === null) {
        await categories.load();
      }
    };

    const tabs = [
      {
        title: 'Menu',
        name: TAB_NAMES.menu,
        icon: 'bars',
        onClick: () => loadCategoryMenu(),
      },
      {
        title: 'Search',
        name: TAB_NAMES.search,
        icon: 'magnifying-glass',
      },
      {
        title: 'Account',
        name: TAB_NAMES.account,
        icon: 'user',
      },
    ];

    watch(route, () => {
      handleFocusOut();
    });

    return {
      isAuthenticated,
      isMobileNav,
      currentTab,
      tabs,
      currentTabComponent: computed(() => `tab-${currentTab.value}`),
      openTab,
      handleFocusOut,
    };
  },
});
