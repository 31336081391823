


















import { defineComponent, PropType, useContext } from '@nuxtjs/composition-api';
import type { TranslateResult } from 'vue-i18n/types';

import Collapsible from '~/components/common/Collapsible.vue';

export default defineComponent({
  components: {
    Collapsible,
  },
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
  },

  setup() {
    const { app } = useContext();
    const { isDesktop }: { isDesktop: boolean } = app.$device;

    return {
      isDesktop,
    };
  },
});
