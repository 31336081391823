import gql from 'graphql-tag';

const fragmentCart = `
  fragment CartFragment on Cart {
    id
    email
    is_virtual
    items {
      uid
      product {
        uid
        __typename
        sku
        url_key
      }
      quantity
    }
    total_quantity
  }
`;

export const cartQuery = gql`
  query cart($cartId: String!) {
    cart(cart_id:$cartId) {
      ...CartFragment
    }
  }
  ${fragmentCart}
`;

export const addProductToCartMutation = gql`
  mutation addProductsToCart($cartId: String!, $cartItems: [CartItemInput!]!) {
    addProductsToCart(cartId: $cartId, cartItems: $cartItems) {
      cart {
        ...CartFragment
      }
      user_errors {
        code
        message
      }
    }
  }
  ${fragmentCart}
`;

export const removeItemFromCartMutation = gql`
  mutation removeItemFromCart($input: RemoveItemFromCartInput) {
    removeItemFromCart(input: $input) {
      cart {
        ...CartFragment
      }
    }
  }
  ${fragmentCart}
`;

export const updateCartItems = gql`
  mutation updateCartItems($input: UpdateCartItemsInput) {
    updateCartItems(input: $input) {
      cart {
        ...CartFragment
      }
    }
  }
  ${fragmentCart}
`;

export const addBuyTogetherItemsToCartMutation = `
  mutation addBuyTogetherItemsToCartMutation($cartId: String!, $mainSku: String!, $additionalSku: String!) {
    buyTogetherAndSave(input: { cart_id: $cartId, main_product_sku: $mainSku, additional_product_sku: $additionalSku })
  }
`;

export const customerCartQuery = gql`
  query customerCart {
    customerCart {
      ...CartFragment
    }
  }
  ${fragmentCart}
`;

export default {
  cartQuery,
  customerCartQuery,
  addProductToCartMutation,
  removeItemFromCartMutation,
  updateCartItems,
};
