























import {
  computed,
  defineComponent, onMounted,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { useExternalCheckout } from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';

export default defineComponent({
  name: 'CartIcon',

  components: {
    SvgImage,
  },

  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { initializeCheckout } = useExternalCheckout();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();

    const goToCart = () => {
      initializeCheckout({ baseUrl: window.location.href });
    };

    onMounted(async () => {
      if (!props.isMobile && cart.value) {
        await loadCartTotalQty();
      }
    });

    return {
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      goToCart,
    };
  },
});
