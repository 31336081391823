import {
  computed,
  onMounted, ref, useRoute, useRouter, watch,
} from '@nuxtjs/composition-api';

import { usePageHelpers } from '~/composables';

import { UseSearchInputInteface } from './useSearchInput';

export function useSearchInput(): UseSearchInputInteface {
  const searchInput = ref<string>('');
  const route = useRoute();
  const router = useRouter();
  const { isSearchPage } = usePageHelpers();

  const routeParams = computed(() => ({
    searchParam: route.value?.query?.q,
    routeName: route.value?.params?.slug,
  }));

  const doSearch = async (searchValue) => {
    const isSearchValue = typeof searchValue === 'string' && searchValue;

    if (isSearchValue || searchInput.value) {
      await router.push(`/search?q=${isSearchValue ? searchValue : searchInput.value}`);
    }
  };

  const changeSearchInputValue = () => {
    if (!isSearchPage.value) {
      searchInput.value = '';
      return;
    }

    const searchValue = Array.isArray(routeParams.value.searchParam) ? routeParams.value.searchParam[0] : routeParams.value.searchParam;

    if (searchValue) {
      searchInput.value = searchValue;
    }
  };

  onMounted(() => {
    changeSearchInputValue();
  });

  watch(route, () => {
    changeSearchInputValue();
  });

  return {
    searchInput,
    doSearch,
  };
}

export default useSearchInput;
export * from './useSearchInput';
