import { Plugin } from '@nuxt/types';
import { ref, set } from '@nuxtjs/composition-api';
import { PiniaPluginContext } from 'pinia';

import ShipmentConfig from '~/plugins/query/ShipmentConfig.gql';

const shipmentConfigPlugin: Plugin = async ({ $pinia, app }) => {
  const shipmentConfig = window && window.localStorage.shipmentConfig
    ? ref(JSON.parse(window.localStorage.getItem('shipmentConfig') ?? '{}'))
    : ref(await app.$vsf.$magento.api.customQuery({ query: ShipmentConfig }));

  $pinia.use(({ store }: PiniaPluginContext) => {
    if (store.$id !== 'magentoConfig') return;
    // eslint-disable-next-line no-prototype-builtins
    if (!store.$state.hasOwnProperty('shipmentConfig')) {
      set(store.$state, 'shipmentConfig', shipmentConfig.value?.data?.shipmentConfig);
    } else {
      // eslint-disable-next-line no-param-reassign
      store.$state.shipmentConfig = shipmentConfig.value?.data?.shipmentConfig;
    }

    window.localStorage.setItem('shipmentConfig', JSON.stringify(shipmentConfig.value));
  });
};

export default shipmentConfigPlugin;
