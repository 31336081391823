
























import { defineComponent, PropType } from '@nuxtjs/composition-api';

interface ListItem {
  path: string,
  value: string,
  isExternal?: boolean
}

export default defineComponent({
  name: 'LinksList',
  props: {
    list: {
      type: Array as PropType<ListItem[]>,
      default: () => [],
    },
  },
});
