




























import {
  computed,
  defineComponent, useCssModule,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { useSearchInput } from '~/composables';
import { APP_ROUTER_PATHS } from '~/constants';

export default defineComponent({
  name: 'SearchInput',

  components: {
    SvgImage,
  },

  props: {
    propsStyles: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const { searchInput, doSearch } = useSearchInput();
    const defaultStyles = useCssModule();

    const computedStyles = computed(() => ({ ...defaultStyles, ...props.propsStyles }));

    return {
      searchInput,
      computedStyles,
      doSearch,
    };
  },

  data() {
    return {
      routes: APP_ROUTER_PATHS,
    };
  },
});
