import { defineStore } from 'pinia';

import { SimpleProductInterface } from '~/modules/catalog/category/components/tiles/simpleProductInterface';

const useTaxStore = defineStore('tax', {
  state: () => ({
    tax: true,
  }),
  actions: {
    setTaxFlag(tax: boolean) {
      this.tax = tax;
    },
  },
});

const getPriceWithTaxAndFormatUsingProduct = (product: SimpleProductInterface) => {
  const { tax } = useTaxStore();
  const price = tax ? product.price_incl_vat : product.price_excl_vat;

  return price.toFixed(2);
};

export {
  getPriceWithTaxAndFormatUsingProduct,
  useTaxStore,
};
