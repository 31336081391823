import { computed, reactive } from '@nuxtjs/composition-api';

import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState';

const state = reactive<StateInterface>({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  isOverlay: false,
  isMobileNav: false,
  modal: {
    identifier: undefined,
    props: undefined,
  },
  modalAdditional: {
    identifier: undefined,
    props: undefined,
  },
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };

  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  const setOverlay = (newState) => {
    state.isOverlay = newState ?? !state.isOverlay;
  };

  const setMobileNav = (newState) => {
    state.isMobileNav = newState ?? !state.isMobileNav;
  };

  const setFilterSidebar = (newState) => {
    state.isFilterSidebarOpen = newState;
  };

  const toggleAdditionalModal = (payload) => {
    state.modalAdditional = payload?.identifier ? payload : {
      identifier: undefined,
      props: undefined,
    };
  };

  const toggleModal = (payload) => {
    state.modal = payload?.identifier ? payload : {
      identifier: undefined,
      props: undefined,
    };

    if (!payload?.identifier) {
      toggleAdditionalModal(null);
    }
  };

  return {
    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    isOverlay: computed(() => state.isOverlay),
    isMobileNav: computed(() => state.isMobileNav),
    modal: computed(() => state.modal),
    modalAdditional: computed(() => state.modalAdditional),
    toggleMobileMenu,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    setOverlay,
    setMobileNav,
    toggleModal,
    toggleAdditionalModal,
    setFilterSidebar,
  };
}

export default useUiState;
export * from './useUiState';
