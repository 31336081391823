import {
  AVAILABLE_PRODUCT_VISIBILITY, HP_BRAND_ID, MAINTENANCE_ATTRIBUTE_IDS, PAGE_SUFFIX,
  PRINTERS_ATTRIBUTE_SET_ID,
} from '~/constants';
import { CompatibilityListInterface, ProductInterface } from '~/modules/GraphQL/types';

export interface ProductHelpers {
  isHP: (product: ProductInterface) => boolean,
  isPrinter: (product: ProductInterface) => boolean,
  isGenuine: (product: ProductInterface) => boolean,
  isHighCapacity: (product: ProductInterface) => boolean,
  isMaintenance: (id: string | number) => boolean,
  getCompatibilityBrand: (list: CompatibilityListInterface[], domain: string) => string,
  getBrandTitle: (list: CompatibilityListInterface[], domain: string) => string,
  getModelName: (brandTitle: string, shortName: string) => string,
  getBrandBulletTitle: (condition: string, anchor: string, value: string) => string,
  checkIsArrow: (product: ProductInterface) => boolean,
  getProductPath: (product: ProductInterface) => string,
  isSwitchAndSave: (product: ProductInterface) => boolean,
  getVimeoId: (url: string) => string | null,
  getYouTubeId: (url: string) => string | null,
}

export const isHP = (product: ProductInterface): boolean => +product.specification_brand === HP_BRAND_ID;

export const isPrinter = (product: ProductInterface) => {
  if (product.attribute_set_id) {
    return +product.attribute_set_id === PRINTERS_ATTRIBUTE_SET_ID;
  }

  return false;
};

export const isGenuine = (product: ProductInterface): boolean => {
  const exp = /Genuine/g;

  return exp.test(product.manufacturer_anchor);
};

export const isHighCapacity = (product: ProductInterface): boolean => {
  const exp = /High Capacity/g;

  return exp.test(product.name);
};

export const isMaintenance = (id: number | string) => MAINTENANCE_ATTRIBUTE_IDS.includes(+id);

export const getCompatibilityBrand = (list: CompatibilityListInterface[] = [], domain: string = '') => {
  const brands = [];

  list.forEach((item) => {
    const urlChunks = item.url ? item.url.replace(domain, '').split('/') : [];
    const brandValue = urlChunks.length > 2 ? urlChunks[2] : '';

    if (brandValue && !brands.includes(brandValue)) {
      brands.push(brandValue);
    }
  });

  return brands.join(' and ');
};

export const getBrandTitle = (list: CompatibilityListInterface[] = [], domain: string = '') => {
  let brand = '';

  if (list.length > 0) {
    const compatiblePrinterUrl = list[0].url.replace(domain, '');

    if (compatiblePrinterUrl) {
      const brandName = compatiblePrinterUrl.replace(/^\/|\/$/g, '').replace('-', ' ').split('/')[1]; // replace first slash
      brand = brandName?.length > 1 ? `${brandName[0].toUpperCase()}${brandName.slice(1)}` : ''; // uppercase first character
    }
  }

  return brand;
};

export const getModelName = (brandTitle: string, shortName: string) => {
  const stripWords = [
    'Compatible',
    'Genuine',
    'Cartridge Save',
    'ink',
    'toner',
    'label tape',
  ];

  if (brandTitle) {
    stripWords.push(brandTitle);
  }

  let model = shortName || '';

  stripWords.forEach((stripWord) => {
    if (model.includes(stripWord)) {
      model = model.replace(stripWord, '');
    }
  });

  return model.trim();
};

export const getBrandBulletTitle = (condition: string, anchor: string, value: string) => {
  if (condition === 'remanufactured') {
    return `Made from recycled ${value} cartridges`;
  }

  const manufacturerAnchor = anchor || '';

  // replace Genuine to Original
  return manufacturerAnchor.replace('Genuine', 'Original');
};

export const checkIsArrow = (product: ProductInterface): boolean => {
  const altProduct = product?.alternative_product;

  if (!altProduct) {
    return false;
  }

  const isAltProduct = altProduct.stock?.is_in_stock && altProduct.stock?.qty > 0;
  const isCrossSellProduct = altProduct.status !== 2 && AVAILABLE_PRODUCT_VISIBILITY.includes(altProduct.visibility);

  return !isHP(product) && !isGenuine(altProduct) && altProduct.id && isCrossSellProduct && isAltProduct;
};

export const getProductPath = (product: ProductInterface) => {
  if (!product) return '/';

  return `/${product?.url_rewrites?.[0]?.url ?? product.url_key}${product.url_suffix || PAGE_SUFFIX}`;
};

export const isSwitchAndSave = (product: ProductInterface) => {
  if (!product) return false;

  return !isGenuine(product) && !isPrinter(product);
};

export const removeDomain = (url: string, domain: string) => (url.includes(domain) ? url.replace(domain, '') : url);

export const getVimeoId = (url: string): string | null => {
  const match = url.match(/vimeo\.com\/(?:.*\/)?(\d+)/);

  return match ? match[1] : null;
};

export const getYouTubeId = (url: string): string | null => {
  const match = url.match(/(?:youtube\.com\/(?:[^/]+\/.*\/|(?:v|e(?:mbed)?)\/|.*[&?]v=)|youtu\.be\/)([\w-]{11})/);

  return match ? match[1] : null;
};

const productHelpers: ProductHelpers = {
  isHP,
  isPrinter,
  isGenuine,
  isHighCapacity,
  isMaintenance,
  getCompatibilityBrand,
  getBrandTitle,
  getModelName,
  getBrandBulletTitle,
  checkIsArrow,
  getProductPath,
  isSwitchAndSave,
  getVimeoId,
  getYouTubeId,
};

export default productHelpers;
