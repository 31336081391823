export const APP_ROUTER_PATHS = {
  home: '/',
  freeDelivery: '/free-delivery.html',
  returns: '/returns.html',
  contact: '/contact.html',
  aboutUs: '/aboutus.html',
  recycling: '/recycling.html',
  moneyBack: '/moneyback.html',
  lowPrice: '/lowprice.html',
  businessAccount: '/business_account.html',
  authorities: '/schools-and-local-authorities.html',
  printers: '/printers.html',

  account: '/customer/account/',
  accountInfo: '/customer/account/edit/',
  logout: '/customer/account/logout/',
  speedyReorder: '/customer/speedy/index/',
  accountAddress: '/customer/address/',
  accountCards: '/vault/cards/listaction/',
  resources: '/news',
  help: 'https://help.cartridgesave.co.uk/hc/en-gb',
  orders: '/sales/order/history/',
  savedPrinters: '/customer/printers/index/',
  rmaReturns: '/rma/returns/history/',
  checkout: '/checkout',
  newsletter: '/newsletter/manage/',
  productReviews: '/review/customer/',
  guestOrders: 'https://www.cartridgesave.co.uk/sales/guest/form/',
  hpSecurity: 'https://support.hp.com/gb-en/document/c05310148?openCLC=true',
  switchSave: '/switch-save',
  search: '/search',
};
