import axios from 'axios';

import { CONFIG } from './config';
import { BackInStock, UseOutOfStockInterface } from './useOutOfStock';

export function useOutOfStock(): UseOutOfStockInterface {
  const requestStockNotification = async (stockDetails: BackInStock) => {
    try {
      return await axios.post(CONFIG.endpoint, JSON.stringify({ notification: stockDetails }), { ...CONFIG.options });
    } catch (err) {
      console.warn('requestStockNotification is unavailable:', err);
      return {
        data: err,
      };
    }
  };

  return {
    requestStockNotification,
  };
}

export * from './useOutOfStock';
export default useOutOfStock;
