





















import type { NuxtError } from '@nuxt/types';
import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

import NotFound from '~/components/NotFound/NotFound.vue';

export default defineComponent({
  components: {
    NotFound,
    SfButton,
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
  head() {
    return {
      title: 'Oh dear, we couldn\'t find that page for you.',
    };
  },
});
