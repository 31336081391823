import get from 'lodash-es/get';
import { defineStore } from 'pinia';

import { ATTRIBUTES_LIST } from '~/composables/useAttributes';
import attributesGql from '~/composables/useAttributes/attributes.gql';
import { Attribute } from '~/modules/GraphQL/types';

interface LabelInterface {
  [key: string]: {
    [key: string]: string,
  }
}

interface Attributes {
  rawAttributes: Attribute[] | null,
  labels: LabelInterface[]
}

export const useAttributesStore = defineStore('attributes', {
  state: (): Attributes => ({
    rawAttributes: null,
    labels: [],
  }),
  actions: {
    async load() {
      const { data }: { data: { customAttributeMetadata?: { items?: Attribute[] } } } = await this.$nuxt.app.$vsf.$magento.api.customQuery({
        query: attributesGql,
        queryVariables: { attributes: ATTRIBUTES_LIST },
      });
      this.rawAttributes = data?.customAttributeMetadata?.items ?? null;
    },
    updateLabels(attributeKey: string, optionId: string, label: string) {
      if (!this.labels[attributeKey]) {
        this.labels[attributeKey] = {};
      }

      this.labels[attributeKey][`${optionId}`] = label;
    },
  },
  getters: {
    attributes(state) {
      if (state.rawAttributes === null) {
        return null;
      }

      return state.rawAttributes;
    },
    getLabelById(state) {
      return (attributeKey: string, optionId: string) => {
        // check cached attribute
        const attrCache = get(state, `labels.${attributeKey}.${optionId}`, null);

        if (attrCache) {
          return {
            label: `${attrCache}`,
            isCached: true,
          };
        }

        const attribute = state.rawAttributes.find((attr) => attr.attribute_code === attributeKey);

        if (attribute) {
          const opt = attribute.attribute_options.find((op) => op.value.toString() === optionId);

          if (opt) {
            return {
              label: opt ? opt.label : optionId,
            };
          }

          return {
            label: optionId,
          };
        }

        return {
          label: optionId,
        };
      };
    },
  },
});
